/**
 * a text input and a button. When press call makeApiCall and render result below
 */
import { useState } from "react";
import { getUnits, calculate } from "./service";
import { CalculateResponse, UnitsResponse, DataPoint } from "./types";
import { Select, Input, Button, InputNumber, message } from "antd";
import { useRequest } from "ahooks";

export default function Calculator() {
  const [formula, setFormula] = useState<string>("");
  const [selectedUnit, setSelectedUnit] = useState<string>("");
  const [map, setMap] = useState<Record<string, DataPoint>>({});
  const [result, setResult] = useState<CalculateResponse>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const unitListRequest = useRequest(getUnits);
  const calculateRequest = useRequest(calculate, {
    manual: true,
  });

  const unitList: UnitsResponse[] = unitListRequest.data
    ? JSON.parse(unitListRequest.data as any) || []
    : [];
  console.log(typeof unitList, unitList, "unitList");

  const unitOptions = unitList.map((item) => {
    return {
      label: item.unitName,
      value: item.unitName,
    };
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
      <p>1. 输入公式，点击Parse</p>
      <p>2. 输入指标数值和单位</p>
      <p>3. 点击Calculate</p>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <Input
          width={200}
          value={formula}
          onChange={(e) => {
            setFormula(e.target.value);
          }}
        />
        <Select
          showSearch
          loading={unitListRequest.loading}
          style={{
            width: 300,
            marginLeft: 20,
          }}
          options={unitOptions}
          value={selectedUnit}
          onChange={(value) => {
            setSelectedUnit(value);
          }}
        />
      </div>
      <Button
        size="large"
        style={{ marginBottom: 20 }}
        onClick={() => {
          const pattern = /`([^`]+)`/g;
          const matches = (formula.match(pattern) || []).map((match) =>
            match.slice(1, -1)
          );

          const dealWithMap: Record<string, DataPoint> = {};
          matches.forEach((item) => {
            dealWithMap[item] = {
              indicatorCode: item,
              unit: map[item]?.unit || "",
              value: map[item]?.value || 0,
            };
          });

          setMap(dealWithMap);
        }}
      >
        Parse
      </Button>
      <div>
        {Object.entries(map).map(([key, item]) => {
          return (
            <div
              key={key}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <span>{key}</span>
              <InputNumber
                style={{ width: 200, marginLeft: 20 }}
                value={item.value}
                onChange={(e) => {
                  setMap({
                    ...map,
                    [key]: {
                      ...item,
                      value: e as number,
                    },
                  });
                }}
              />
              <Select
                showSearch
                loading={unitListRequest.loading}
                style={{ width: 200, marginLeft: 20 }}
                value={item.unit}
                options={unitOptions}
                onChange={(value) => {
                  setMap({
                    ...map,
                    [key]: {
                      ...item,
                      unit: value,
                    },
                  });
                }}
              />
            </div>
          );
        })}
      </div>
      <Button
        onClick={async () => {
          try {
            const result = await calculateRequest.runAsync({
              formula: formula,
              tokenMap: map,
              targetUnit: selectedUnit,
            });
            console.log(result, "result");
            setResult(JSON.parse(result as any));
            setErrorMessage("");
          } catch (e: any) {
            console.error(e);
            message.error(e.message || "啊啊啊啊");
            setErrorMessage(e.response.data || "error");
            setResult(undefined);
          }
        }}
      >
        Calculate
      </Button>
      <div>
        <p>Result</p>
        <p>
          {result?.value} {result?.calculateUnit}
        </p>
        <br />
        <p>Error</p>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
}
