import axios from "axios";
import {
  CalculateRequest,
  CalculateResponse,
  GenresResponse,
  UnitsResponse,
} from "./types";

// Define types for the API requests and responses
export async function getGenres(): Promise<GenresResponse[] | null> {
  try {
    const genresResponse = await axios.get(
      "http://localhost:8080/metric/test/genres"
    );
    return genresResponse.data;
  } catch (error: any) {
    console.error("Error:", error.message);
    return null;
  }
}

export async function getUnits(): Promise<UnitsResponse[] | null> {
  try {
    const unitsResponse = await axios.get(
      "http://localhost:8080/metric/test/units"
    );
    return unitsResponse.data;
  } catch (error: any) {
    console.error("Error:", error.message);
    return null;
  }
}

export async function calculate(
  calculateRequest: CalculateRequest
): Promise<CalculateResponse | string> {
  try {
    const calculateResponse = await axios.post(
      "http://localhost:8080/metric/test/calculate",
      calculateRequest
    );
    return calculateResponse.data;
  } catch (error: any) {
    console.error("Error:", error.message);
    console.error("Error:", JSON.stringify(error));
    throw error;
  }
}
